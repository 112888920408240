import { statusColors } from 'src/const';

export const countMachineStatusData = (entities: Array<Record<string, string>>) => {
  const status: Record<string, Array<any>> = {
    critical: [],
    alarm: [],
    monitor: [],
    healthy: [],
    na: [],
  };
  const data = [];

  for (const item of entities) {
    const machineStatus = Object.values(item)[0];
    status[machineStatus].push(Object.entries(item));
  }

  for (const key in status) {
    const length = status[key].length;
    data.push({
      value: length,
      name: key,
      itemStyle: {
        color: statusColors[key],
      },
    });
  }

  return [data, status];
};

const tooltipFormatter = (status: any) => {
  return (params: any) => {
    const { name, color } = params;
    const value = name === 'na' ? 'Not Monitored' : name;
    const machines = status[name];
    let machinesList = '';

    machines.forEach((item: Array<string>) => (machinesList += `${item[0][0]}<br>`));

    return `<div style="color: #021d3d; font-size: 12px; padding-left: 4px; padding-right: 4px">
                  <div style="display: flex; align-items: center; ">
                    <div style="width: 8px; height: 8px; margin-right: 8px; border-radius: 100%; background-color: ${color}"></div>
                    <div style="text-transform: capitalize; font-weight: 600">${value}</div>
                  </div>
                  <div style="padding-top: 12px">${machinesList}</div>
                </div>`;
  };
};

const getTotalMachines = (status: any) => {
  let total = 0;

  for (const item in status) {
    total += status[item].length;
  }

  return total;
};

export const getOption = (data: any, status: any) => {
  return {
    animation: false,
    textStyle: {
      fontFamily: `'Montserrat', sans-serif`,
      fontWeight: 500,
      color: '#021d3d',
    },
    title: {
      padding: 0,
      text: 'Total',
      textStyle: {
        color: '#01152B',
        lineHeight: 15,
        fontSize: 13,
        fontWeight: 500,
      },
      subtext: getTotalMachines(status),
      subtextStyle: {
        color: '#01152B',
        fontSize: 25,
        fontWeight: 600,
      },
      left: 'center',
      top: '40%',
      itemGap: 10
    },
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormatter(status),
      textStyle: {
        color: '#021d3d',
        fontSize: 12,
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['70%', '85%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };
};
