import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  StackProps,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { DMArrowDownIcon, DMArrowUpIcon } from 'src/components/Icons';
import { SENSORS_DATA_TYPE } from 'src/const';

interface DropdownItemProps {
  label: string;
  isDisabled: boolean;
  isSelected: boolean;
  props: StackProps;
  handleClick: () => void;
}

const DropdownItem = ({ label, isDisabled, isSelected, props, handleClick }: DropdownItemProps) => (
  <HStack
    justifyContent="space-between"
    alignItems="center"
    pl="1rem"
    pr="1.25rem"
    gap={0}
    _hover={{ background: isDisabled ? 'initial' : '#E6E8EC80' }}
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    opacity={isDisabled ? '0.4' : '1'}
    pointerEvents={isDisabled ? 'none' : 'initial'}
    onClick={handleClick}
    {...props}
  >
    <Box fontWeight={isSelected ? '600' : '500'} fontSize="0.875rem">{label}</Box>
    {isSelected && <CheckIcon color="#01152B" />}
  </HStack>
);

interface SensorsDataRadioGroupProps {
  activeSensorsDataType: string;
  setActiveSensorsDataType: Dispatch<SetStateAction<string>>;
  isComponentHasVibrationSensors: boolean;
  isMachineHasOtherSensors: boolean;
}

export const SensorsDataDropdown = ({
  activeSensorsDataType,
  setActiveSensorsDataType,
  isComponentHasVibrationSensors,
  isMachineHasOtherSensors,
}: SensorsDataRadioGroupProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleItemClick = (value: string) => {
    setActiveSensorsDataType(value);
  };

  const isSensorFusionSelected = activeSensorsDataType === SENSORS_DATA_TYPE.PI;
  const isSensorFusionDisabled = !isMachineHasOtherSensors && !isComponentHasVibrationSensors;

  const isSpectrumSelected = activeSensorsDataType === SENSORS_DATA_TYPE.FFT;
  const isSpectrumDisabled = !isComponentHasVibrationSensors;

  const buttonLabel = isSensorFusionSelected ? 'Sensor Fusion' : isSpectrumSelected ? 'Spectrum' : '-----------';

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start" gutter={4}>
      <PopoverTrigger>
        <Button
          rightIcon={isOpen ? <DMArrowUpIcon w="24px" h="24px" /> : <DMArrowDownIcon w="24px" h="24px" />}
          variant="naked"
          fontSize="0.875rem"
          size="lg"
          height="47px"
          width="170px"
          gap={0}
          justifyContent="space-between"
          fontWeight="500"
          borderRadius="0.5rem"
          paddingX="1rem"
          border={isOpen ? '1px solid #6667DD' : '1px solid #B1B9C3'}
          bgColor={isOpen ? '#6667DD' : 'none'}
          color={isOpen ? '#fff' : '#01152B'}
          onClick={onToggle}
        >
          {buttonLabel}
        </Button>
      </PopoverTrigger>

      <PopoverContent border="1px solid #B1B9C3" borderRadius="0.5rem" w="240px" boxShadow="0px 2px 8px 0px #021D3D1F">
        <PopoverBody p={0}>
          <DropdownItem
            label="Sensor Fusion"
            isDisabled={isSensorFusionDisabled}
            isSelected={isSensorFusionSelected}
            props={{
              borderTopRadius: '0.5rem',
              pt: '1.25rem',
              pb: '0.75rem',
            }}
            handleClick={() => handleItemClick(SENSORS_DATA_TYPE.PI)}
          />
          <DropdownItem
            label="Spectrum"
            isDisabled={isSpectrumDisabled}
            isSelected={isSpectrumSelected}
            props={{
              borderBottomRadius: '0.5rem',
              pt: '0.75rem',
              pb: '1.25rem',
            }}
            handleClick={() => handleItemClick(SENSORS_DATA_TYPE.FFT)}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
